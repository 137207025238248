import { NgModule, APP_INITIALIZER, ComponentFactoryResolver } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { CookieModule } from 'ngx-cookie';
import { AppLoadService } from './services/app-load/app-load.service';
import { CloseSessionModalComponent } from './components/modals/close-session/close-session-modal.component';
import { PopoverHeaderProfileComponent } from './components/popover-header-profile/popover-header-profile.component';
import { CommonModule } from '@angular/common';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from 'ionic-angular';
import { SendMailComponent } from './components/modals/send-mail/send-mail.component';
import { MailPillComponent } from './components/mail-pill/mail-pill.component';
import { HeaderComponent } from './components/header/header.component';

export function init_app_language(appLoadService: AppLoadService): any {
  return () => appLoadService.initializeAppLanguage();
}

export function init_app_componentConfig(appLoadService: AppLoadService): any {
  return () => appLoadService.initializeAppComponentConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    CloseSessionModalComponent,
    PopoverHeaderProfileComponent,
    SendMailComponent,
    MailPillComponent
  ],
  entryComponents: [
    PopoverHeaderProfileComponent,
    SendMailComponent,
    MailPillComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CookieModule.forRoot(),
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    HttpClientModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppLoadService,
    FileOpener,
    File,
    Platform,
    { provide: APP_INITIALIZER, useFactory: init_app_componentConfig, deps: [AppLoadService], multi: true },
    { provide: APP_INITIALIZER, useFactory: init_app_language, deps: [AppLoadService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
