import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Content, NavController, ViewController, Platform } from 'ionic-angular';
import * as _ from 'lodash';
import * as jQuery from 'jquery';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private platform: Platform,
    private opener: FileOpener,
    private file: File,
    private alertController: AlertController
  ) { }

  static isFunction(object: any): boolean {
    return typeof object === 'function';
  }

  /* strings */

  static isString(string: string): boolean {
    return typeof string === 'string';
  }

  static hasLength(value: string): boolean {
    return !_.isNil(value) && value.length > 0;
  }

  static hasEmptyString(string: string): boolean {
    return this.isString(string) && this.hasLength(string) && (string === "null" || string === "undefined");
  }

  static isEmptyString(string: string): boolean {
    return !this.isString(string) || !this.hasLength(string) || this.hasEmptyString(string);
  }

  static getPrefixedString(baseString: string, prefixString: string): string {
    const prefix: string = _.size(prefixString) ? `${prefixString}-` : ``;
    return `${prefix}${baseString}`;
  }

  static getTimeStringHours(timeString: string): number {
    const timeStringTokens: string[] = timeString.split(':');
    return timeStringTokens.length >= 1 ? parseInt(timeStringTokens[0], 10) : undefined;
  }

  static getTimeStringMinutes(timeString: string): number {
    const timeStringTokens: string[] = timeString.split(':');
    return timeStringTokens.length >= 2 ? parseInt(timeStringTokens[1], 10) : undefined;
  }

  static getTimeStringSeconds(timeString: string): number {
    const timeStringTokens: string[] = timeString.split(':');
    return timeStringTokens.length >= 3 ? parseInt(timeStringTokens[2], 10) : undefined;
  }

  static equalsIgnoreCase(foo: string, bar: string): boolean {
    return foo.toLowerCase() === bar.toLowerCase();
  }

  static generateUniqueId(): string {
    return Math.random().toString().replace(/\D/g, '');
  }

  /* objects */

  static isObject(object: any): boolean {
    return typeof object === 'object' && object.length === undefined;
  }

  static copy(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  static equals(foo: any, bar: any): boolean {
    return JSON.stringify(foo) === JSON.stringify(bar);
  }

  static removeNilKeys(object: any): void {
    Object.keys(object).forEach(key => !object[key] && object[key] !== 0 ? delete object[key] : '');
  }

  /* arrays */

  static isEmptyArray(array: Array<any>) {
    return (!array || typeof array === 'undefined' || 0 === array.length);
  }

  static isArray(object: any): boolean {
    return typeof object === 'object' && object.length !== undefined;
  }

  static contains(iterable: any[], value: any): boolean {
    let contained = false;

    iterable.forEach((entry: any) => {
      if (this.equals(entry, value)) {
        contained = true;
      }
    });

    return contained;
  }

  static containsByField(iterable: any[], value: any, fieldName: string): boolean {
    return this.getByField(iterable, value, fieldName) !== undefined;
  }

  static getByField(iterable: any[], value: any, fieldName: string): any {
    let foundEntry;
    iterable.forEach((entry: any) => {
      if (this.equals(entry[fieldName], value[fieldName])) {
        foundEntry = entry;
      }
    });

    return foundEntry;
  }

  static isLast(index: number, array: any[]): boolean {
    return index === array.length - 1;
  }

  static moveArrayEntry(array: any[], currentIndex: number, newIndex: number): void {
    array.splice(newIndex, 0, array.splice(currentIndex, 1)[0]);
  }

  /* math */

  static getRandomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static getRandomBoolean(): boolean {
    return Math.random() >= .5;
  }

  /* DOM */

  static isControlChecked(control: FormControl): any {
    return control.value !== true ? { notChecked: true } : undefined;
  }

  static scrollToContent(content: Content): void {
    if (!_.isNil(content)) {
      content.scrollToTop();
    }
  }

  static scrollToId(id: string): void {
    if (!_.isNil(id)) {
      const element: HTMLElement = document.getElementById(id);
      if (!_.isNil(element)) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  static focusForm(formSelector: string): void {
    setTimeout(() => {
      const form: any = jQuery(formSelector);
      if (form.length) {
        const inputs: any = form.find('input');
        if (inputs.length) {
          const firstInput = inputs.eq(0);
          const inputValue = firstInput.val();
          firstInput.focus().val('').val(inputValue);
        }
      }
    }, 150);
  }

  /* browsers */

  static onIE(): boolean {
    const matches: any[] = navigator.userAgent.match(/Trident/);
    return matches && matches.length > 0;
  }

  static onEdge(): boolean {
    const matches: any[] = navigator.userAgent.match(/Edge/);
    return matches && matches.length > 0;
  }

  static onChrome(): boolean {
    const matches: any[] = navigator.userAgent.match(/Chrome/);
    return matches && matches.length > 0;
  }

  /* ionic */

  static getLastViewName(navController: NavController): string {
    return this.getViewControllerName(navController.last());
  }

  static getLastViewParameters(navController: NavController): any {
    return navController.last() ? navController.last().getNavParams() : null;
  }

  static getPreviousViewName(navController: NavController): string {
    return this.getViewControllerName(navController.getPrevious());
  }

  static getViewControllerName(viewController: ViewController): string {
    return viewController.name
    || (viewController.instance ? viewController.instance.name : '')
    || (viewController.component ? viewController.component.name : '');
  }
  static output(data: any): void {
    window.console.log(JSON.stringify(data, undefined, 2));
  }

  isCordovaPlatform() {
    return this.platform.is('cordova');
  }

  openBlobFile(pdf: string, filename: string) {
    return new Promise((resolve, reject) => {
      if (this.isCordovaPlatform()) {
        const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
        this.file.writeFile(writeDirectory, filename, this.convertBase64ToBlob(pdf, 'data:application/pdf;base64'), {replace: true})
          .then(() => {
              this.opener.open(writeDirectory + filename, 'application/pdf')
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                    console.error('Error opening pdf file %j', error);
                    reject();
                });
          })
          .catch((error) => {
              console.error('Error writing pdf file %j', error);
              reject();
          });
      } else {
        const fileURL = URL.createObjectURL(this.convertBase64ToBlob(pdf, 'data:application/pdf;base64'));
        let anchorElement = document.createElement("a");
        document.body.appendChild(anchorElement);
        anchorElement.style.display = "none";
        anchorElement.href = fileURL;
        anchorElement.download = filename;
        anchorElement.click();
        window.URL.revokeObjectURL(fileURL);
        document.body.removeChild(anchorElement);
        resolve();
      }
    });
  }

  convertBase64ToBlob(b64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
         const slice = byteCharacters.slice(offset, offset + sliceSize);
         const byteNumbers = new Array(slice.length);
         for (let i = 0; i < slice.length; i++) {
             byteNumbers[i] = slice.charCodeAt(i);
         }
         const byteArray = new Uint8Array(byteNumbers);
         byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  /* alert */

  async showGenericAlert(title:string, message: string, message_button: string) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [message_button]
    });
    await alert.present();
  }
}
