import { ComponentConfiguration } from './component-configuration';

export const defaultComponentsConfiguration: ComponentConfiguration = {
    defaultLanguage: 'es-ES',
    forcedLanguageKey: 'forcedLanguage',
    userDataCookieName: 'userInfoMOD',
    userDataType: 'userDataTypeMOD',
    userCookieName: 'userNameMOD',
    clientIdCookieName: 'clientIdMOD',
    sfHostCookieName: 'sfHostMOD',
    countryCodeCookieName: 'countryCodeMOD',
    rememberedUserCookieName: 'rememberedUserMOD',
    securityPinId: 'securityPin',
    fingerprintEnabledId: 'fingerprintEnabled',
    apiKeyID: 'apiKey',
    rememberedUserCookieExpirationInDays: 365,
    numberPattern: '[0-9]*',
    spacedNumberPattern: '[0-9 ]*',
    phonePattern: '(\\+[0-9 ]*)?[0-9 ]*',
    datePattern: 'DD/MM/YYYY',
    datePickerPattern: 'DD MMMM YYYY',
    emptyField: '-',
    colors: {
      primary: '#D81E05'
    },
    clientPermissionsCookieName: 'clientPermissions'
};

export const menuConfiguration: any[] = [
  {
    option: {
      icon: 'home',
      page_ref: {
        nav_params: null,
        view_name: 'home'
      },
      text: 'Inicio',
      id: '0'
    }
  }
];
