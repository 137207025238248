import { Component, OnInit, ElementRef, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { TranslationService } from 'src/app/services/translation/translation.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { ModalController, LoadingController } from '@ionic/angular';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MailPillComponent } from '../../mail-pill/mail-pill.component';
import { ApiService } from 'src/app/services/api/api.service';
import { NavExtrasService } from 'src/app/services/nav-extras/nav-extras.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss'],
})
export class SendMailComponent implements OnInit {
  listOfMails: string[];
  addEmailForm: FormGroup;
  maxNumberOfMails = 100;
  type: string;
  companyId: string;
  translate;
  @ViewChild('emailsContainer', { read: ViewContainerRef }) container: any;
  constructor(
    private platformService: PlatformService,
    private translationService: TranslationService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private resolver: ComponentFactoryResolver,
    private loadingController: LoadingController,
    private apiService: ApiService,
    private navExtrasService: NavExtrasService,
    private utilsService: UtilsService
  ) {
    this.translationService.bind(this);
    this.platformService.bindTo(this);
    this.type = navExtrasService.getExtras('type');
    this.companyId = navExtrasService.getExtras('companyId');
  }

  ngOnInit() {
    this.listOfMails = [];
    this.prepareForm();
  }

  async dismiss() {
    this.modalController.dismiss({save: false});
  }

  prepareForm() {
    this.addEmailForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.maxLength(70),
        Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'),
        Validators.required]))
    });

  }

  onAddEmail() {
    if (this.isValidForm()) {
      const newEmail = this.addEmailForm.get('email').value;
      if (!this.isFullList() && this.listOfMails.indexOf(newEmail) === -1) {
        this.listOfMails.push(newEmail);
        const factory = this.resolver.resolveComponentFactory(MailPillComponent);
        const componentRef = this.container.createComponent(factory);
        (componentRef.instance as MailPillComponent).email = newEmail;
        (componentRef.instance as MailPillComponent).removeElement.subscribe(() => {
          this.listOfMails.splice(this.listOfMails.indexOf(newEmail), 1);
          this.container.remove(this.container.indexOf(componentRef));
        });
      }
      this.addEmailForm.get('email').setValue('');
      this.addEmailForm.updateValueAndValidity();
    }
  }

  isValidForm() {
    return this.addEmailForm.valid && !this.isFullList();
  }

  isFullList() {
    return this.listOfMails.length >= this.maxNumberOfMails;
  }

  isEmptyList() {
    return this.listOfMails.length === 0;
  }

  async send() {
    let title = this.translationService.getValueForKey('generic_alert.title_post_mail'),
    message = this.translationService.getValueForKey('generic_alert.message_post_mail'),
    titleError = this.translationService.getValueForKey('generic_error_alert.title_post_mail'),
    messageError = this.translationService.getValueForKey('generic_error_alert.message_post_mail'),
    message_button = this.translationService.getValueForKey('generic_alert.message_button');

    const loading = await this.loadingController.create({
      spinner: 'dots',
      message: this.translationService.getValueForKey('generic.sending'),
      translucent: true
    });
    loading.present();
    this.apiService.postMails(this.companyId, this.type, this.listOfMails).toPromise().then((res: any) => {
      loading.dismiss();
      this.dismiss();
      this.utilsService.showGenericAlert(title, message, message_button);
    }).catch((error: any) => {
      loading.dismiss();
      if(UtilsService.isObject(error) && !UtilsService.isEmptyArray(error.error) && UtilsService.hasLength(error.error[0].message) && error.error[0].message.includes('no completed Account Form')) {
        messageError = this.translationService.getValueForKey('generic_error_alert.message_post_mail_finish_form');
        this.dismiss();
      }
      this.utilsService.showGenericAlert(titleError, messageError, message_button);
    });
  }

  getType() {
    return this.type === 'risksmap' ?
      this.translationService.getValueForKey('company-operative-selection.risks-map').toLowerCase() :
      this.translationService.getValueForKey('company-operative-selection.route-map').toLowerCase();
  }

  getCompanyId() {
    return this.companyId;
  }
}
