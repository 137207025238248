import { Injectable, Injector } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { User } from 'src/app/models/user';
import { DateService } from '../date/date.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { CookieService } from 'ngx-cookie';
import { IdentificationService } from '../identification/identification.service';
import { NavController } from 'ionic-angular';
import { Storage } from '@ionic/storage';
import * as _ from 'lodash';
import { LocalStorageService } from '../local-storage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

    private username: string;
    private userDataOpen: User;
    private storage: Storage;
    private cookieService: CookieService;
    private countryCodes = {
        España: 'ES',
        Mexico: 'MX'
    };

    constructor(
        private injector: Injector,
        private identificationService: IdentificationService,
        private dateService: DateService,
        private localStorage: LocalStorageService
    ) {
        this.storage = injector.get(Storage);
        this.cookieService = injector.get(CookieService);
    }


    setPermissions(permissions: any): void {
        const permissionsString: string = JSON.stringify(permissions);
        this.cookieService.put(ConfigurationService.config.clientPermissionsCookieName, permissionsString);
    }

    getPermissions(): any {
        return JSON.parse(this.cookieService.get(ConfigurationService.config.clientPermissionsCookieName));
    }

    setUserDataOpen(userData: User): void {
        this.userDataOpen = userData;
    }

    getUserDataOpen(): User {
        return this.userDataOpen;
    }

    isTemporarilyLoggedIn(): boolean {
        return (this.userDataOpen !== null && this.userDataOpen !== undefined);
    }

    deleteUserDataOpen(): void {
        this.userDataOpen = null;
    }

    getLogout(): void {
        this.deleteClientId();
        this.deleteUserData();
        this.setUsername(null);
        this.identificationService.deleteApiKey();
        this.localStorage.setResource('tasksConsulted', null);
    }

    setRememberedUser(userToRemember: string): void {
        this.cookieService.put(ConfigurationService.config.rememberedUserCookieName, `${userToRemember}`, {
            expires: this.dateService.getDatePlusDays(new Date(), ConfigurationService.config.rememberedUserCookieExpirationInDays)
        });
    }

    setUsername(username: string): void {
        this.username = username;
    }

    getUsername(): string {
        return this.username;
    }

    getRememberedUser(): string {
        return this.cookieService.get(ConfigurationService.config.rememberedUserCookieName);
    }

    clearRememberedUser(): void {
        this.cookieService.remove(ConfigurationService.config.rememberedUserCookieName);
    }

    setClientId(clientId: string): void {
        this.cookieService.put(ConfigurationService.config.clientIdCookieName, clientId);
    }

    getClientId(): string {
        return this.cookieService.get(ConfigurationService.config.clientIdCookieName);
    }

    setCountryIsoCode(country: string): void {
        const countryCode = this.countryCodes && this.countryCodes[country] ? this.countryCodes[country] : '';
        this.cookieService.put(ConfigurationService.config.countryCodeCookieName, countryCode);
    }

    getCountryIsoCode(): string {
        return this.cookieService.get(ConfigurationService.config.countryCodeCookieName);
    }

    deleteClientId(): void {
        this.cookieService.remove(ConfigurationService.config.clientIdCookieName);
    }

    setSfHost(sfHost: string): void {
        this.cookieService.put(ConfigurationService.config.sfHostCookieName, sfHost);
    }

    getSfHost(): string {
        return this.cookieService.get(ConfigurationService.config.sfHostCookieName);
    }

    setUserName(userName: string): void {
        this.cookieService.put(ConfigurationService.config.userCookieName, userName);
    }

    getUserName(): string {
        return this.cookieService.get(ConfigurationService.config.userCookieName);
    }

    setUserData(userData: User): void {
        const userDataString: string = JSON.stringify(userData);
        this.cookieService.put(ConfigurationService.config.userDataCookieName, userDataString);
        this.storage.set(ConfigurationService.config.userDataCookieName, userDataString);
    }

    getUserData(): Observable<User> {
    return of(this.getLoggedUserData());
    }

    redirectWhenNotLoggedIn(navController: NavController): void {
    if (!this.isLoggedIn()) {
        navController.setRoot('LoginPage');
    }
    }

    getLoggedUserData(): User {
    const userDataString: string = this.cookieService.get(ConfigurationService.config.userDataCookieName);
    return userDataString ? JSON.parse(userDataString) : undefined;
    }

    getAppUserData(): Promise<User> {
    return new Promise((resolve, reject) => {
        this.storage.get(ConfigurationService.config.userDataCookieName).then((userDataString) => {
        resolve(userDataString ? JSON.parse(userDataString) : undefined);
        });
    });
    }

    deleteUserData(): void {
        this.cookieService.remove(ConfigurationService.config.userDataCookieName);
    }

    isLoggedIn(): boolean {
    return !_.isEmpty(this.cookieService.get(ConfigurationService.config.userDataCookieName))
        && !_.isEmpty(this.getUserData());
    }
}
