import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule',
    canActivate: [AuthGuardService] },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule',
    canActivate: [AuthGuardService] },
  { path: 'company/:id/form', loadChildren: './pages/risks-form/risks-form.module#RisksFormPageModule',
    canActivate: [AuthGuardService] },
  { path: 'tasks', loadChildren: './pages/tasks/tasks.module#TasksPageModule',
    canActivate: [AuthGuardService] },
  { path: 'company/:id', loadChildren: './pages/company/company.module#CompanyPageModule',
    canActivate: [AuthGuardService] },
  { path: 'company/:id/form/resume', loadChildren: './pages/resume/resume.module#ResumePageModule',
    canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
